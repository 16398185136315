import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Dashboard.vue'),
            meta: {
                pageTitle: 'Dashboard',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
                resource : 'dashboard',
                action : 'consulter-dashboard',
             
            },
        },
        {
            path: '/catalogue',
            name: 'catalogue',
            component: () => import('@/views/vehicules/Catalogue/CataloguesList.vue'),
            meta: {
                pageTitle: 'Catalogue',
                contentClass: 'ecommerce-application',
                resource : 'vehicule',
                action : 'consulter-catalogue',
                breadcrumb: [
                    {
                        text: 'Véhicule',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/catalogue/:id',
            name: 'catalogue-view',
            component: () => import('@/views/vehicules/Catalogue/CatalogueView'),
            meta: {
                pageTitle: 'Véhicules',
                contentClass: 'ecommerce-application',
                resource : 'vehicule',
                action : 'consulter-catalogue',
                navActiveLink : 'catalogue',
                breadcrumb: [
                    {
                        text: 'Catalogue',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/reprise',
            name: 'reprise',
            component: () => import('@/views/vehicules/Reprise/ReprisePage.vue'),
            meta: {
                pageTitle: 'Reprise',
                breadcrumb: [
                    {
                        text: 'Véhicule',
                        active: true,
                    },
                ],
                resource : 'vehicule',
                action : 'consulter-reprise',
            },
        },
        {
            path: '/reprise/creer',
            name: 'creer-reprise',
            component: () => import('@/views/vehicules/Reprise/RepriseAdd.vue'),
            meta: {
                pageTitle: 'Véhicules',
                navActiveLink : 'reprise',
                breadcrumb: [
                    {
                        text: 'Reprise',
                    
                    },
                    {
                        text: 'Créer',
                        active: true,
                    },
                ],
                resource : 'vehicule',
                action : 'consulter-reprise',
            },
        },
        {
            path: '/reprise/:id',
            name: 'reprise-view',
            component: () => import('@/views/vehicules/Reprise/RepriseView.vue'),
            meta: {
                pageTitle: 'Reprise',
                navActiveLink : 'reprise',
                resource : 'vehicule',
                action : 'consulter-reprise',
            },

        },
        {
            path: '/gestion-de-stock',
            name: 'gestion-de-stock',
            component: () => import('@/views/vehicules/GestionStock/GestionStockPage'),
            meta: {
                pageTitle: 'Géstion de stock',
                breadcrumb: [
                    {
                        text: 'Véhicule',
                        active: true,
                    },
                ],
                resource : 'vehicule',
                action : 'consulter-gestion-stock',
            },

        },
        {
            path: '/gestion-de-stock/creer-vehicule',
            name: 'creer-vehicule',
            component: () => import('@/views/vehicules/GestionStock/VehiculeAddPage'),
            meta: {
                pageTitle: 'Véhicules',
                parent : 'gestion-de-stock',
                navActiveLink : 'gestion-de-stock',
                resource : 'vehicule',
                action : 'creer-modifier-stock',
                breadcrumb: [
                    {
                        text: 'Gestion de stock',

                    },
                    {
                        text: 'Création véhicule',
                        active: true,
                    },
                ],

            },
        },
        {
            path: '/gestion-de-stock/:id',
            name: 'editer-vehicule',
            component: () => import('@/views/vehicules/GestionStock/VehiculeEditPage'),
            meta: {
                pageTitle: 'Véhicules',
                navActiveLink : 'gestion-de-stock',
                breadcrumb: [
                    {
                        text: 'Gestion de stock',
                        active: true,
                    },
                ],
                resource : 'vehicule',
                action : 'consulter-gestion-stock',
            },
        },
        
        {
            path: '/vendu',
            name: 'vendu',
            component: () => import('@/views/vehicules/VenuPage.vue'),
            meta: {
                pageTitle: 'Vendu',
                resource : 'vendu',
                action : 'consulter-vendu',
                breadcrumb: [
                    {
                        text: 'Véhicule',
                        active: true,
                    },
                ],
                
            },
        },
        {
            path: '/marchand',
            name: 'marchand',
            component: () => import('@/views/marchand/MarchandPage'),
            meta: {
                pageTitle: 'Marchand',
                resource  : 'marchand',
                action: 'consulter-marchand',
                breadcrumb: [
                    {
                        text: 'Marchand',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/marchand/creer-offre',
            name: 'creer-offre-marchand',
            component: () => import('@/views/marchand/MarchandOffreView'),
            meta: {
                pageTitle: 'Créer offre',
                navActiveLink : 'marchand',
                resource  : 'marchand',
                action: 'consulter-marchand',
                breadcrumb: [
                    {
                        text: 'Marchand',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/marchand/:id',
            name: 'editer-offre-marchand',
            component: () => import('@/views/marchand/MarchandOffreView'),
            meta: {
                pageTitle: 'Créer offre',
                navActiveLink : 'marchand',
                resource  : 'marchand',
                action: 'consulter-marchand',
                breadcrumb: [
                    {
                        text: 'Marchand',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/list-des-bc',
            name: 'list-des-bc',
            component: () => import('@/views/ListeBCPage.vue'),
            meta: {
                pageTitle: 'List des BC',
                resource : 'list-bdc',
                action : 'consulter-liste-offres',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/list-des-offres',
            name: 'list-des-offres',
            component: () => import('@/views/offres/OffreListPage'),
            meta: {
                pageTitle: 'List des Offres',
                resource : 'list-bdc',
                action : 'consulter-liste-offres',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/list-des-offres/:id',
            name: 'offre-view',
            component: () => import('@/views/offres/OffreViewPage'),
            meta: {
                pageTitle: 'Détail offre',
                resource : 'list-bdc',
                action : 'consulter-liste-offres',
                navActiveLink : 'list-des-offres',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/clients',
            name: 'clients',
            component: () => import('@/views/clients/ClientsPage.vue'),
            meta: {
                pageTitle: 'Clients',
                resource : 'client',
                action : 'consulter-client',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/clients/:id/editer',
            name: 'editer-client',
            component: () => import('@/views/clients/ClientsEdit.vue'),
            meta: {
                pageTitle: 'Clients',
                parent : 'clients',
                navActiveLink : 'clients',
                resource : 'client',
                action : 'consulter-client',
                breadcrumb: [
                    {
                        text: 'Editer client',
                    },

                ],

            },
        },
        {
            path: '/clients/creer',
            name: 'creer-client',
            component: () => import('@/views/clients/ClientsAdd'),
            meta: {
                pageTitle: 'Clients',
                parent : 'clients',
                resource : 'client',
                action : 'creer-client',
                navActiveLink : 'clients',
                breadcrumb: [
                    {
                        text: 'Ajouter client',

                    },
                    
                ],

            },
        },
        {
            path: '/livraisons',
            name: 'livraisons',
            component: () => import('@/views/LivraisonsPage.vue'),
            meta: {
                pageTitle: 'Livraisons',
                resource : 'livraison',
                action : 'consulter-livraison',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sites',
            name: 'sites',
            component: () => import('@/views/parametres/sites/SitesList'),
            meta: {
                pageTitle: 'Sites',
                resource: 'site',
                action: 'manage-site',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sites/creer',
            name: 'creer-site',
            component: () => import('@/views/parametres/sites/SiteAdd'),
            meta: {
                pageTitle: 'Sites',
                resource: 'site',
                action: 'manage-site',
                navActiveLink : 'sites',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sites/:id',
            name: 'editer-site',
            component: () => import('@/views/parametres/sites/SiteEdit'),
            meta: {
                pageTitle: 'Sites',
                resource: 'site',
                action: 'manage-site',
                navActiveLink : 'sites',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/roles',
            name: 'roles',
            component: () => import('@/views/parametres/roles/RoleListPage'),
            meta: {
                pageTitle: 'Role',
                resource: 'role',
                action: 'gestion-role',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/roles/:id',
            name: 'editer-role',
            component: () => import('@/views/parametres/roles/RoleViewPage'),
            meta: {
                pageTitle: 'Role',
                resource: 'role',
                action: 'gestion-role',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/utilisateurs',
            name: 'utilisateurs',
            component: () => import('@/views/parametres/utilisateurs/UtilisateursList'),
            meta: {
                pageTitle: 'Utilisateurs',
                resource: 'utilisateur',
                action: 'manage-utilisateur',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/utilisateurs/creer',
            name: 'creer-utilisateur',
            component: () => import('@/views/parametres/utilisateurs/UtilisateursAdd'),
            meta: {
                pageTitle: 'Utilisateurs',
                resource: 'utilisateur',
                action: 'manage-utilisateur',
                navActiveLink : 'utilisateurs',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/utilisateurs/:id',
            name: 'editer-utilisateur',
            component: () => import('@/views/parametres/utilisateurs/UtilisateursEdit'),
            meta: {
                pageTitle: 'Utilisateurs',
                resource: 'utilisateur',
                action: 'manage-utilisateur',
                navActiveLink : 'utilisateurs',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },

        {
            path: '/concessions',
            name: 'concessions',
            component: () => import('@/views/parametres/concessions/ConcessionsList'),
            meta: {
                pageTitle: 'Concessions',
                resource: 'concession',
                action: 'manage-concession',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/concessions/creer',
            name: 'creer-concession',
            component: () => import('@/views/parametres/concessions/ConcessionAdd'),
            meta: {
                pageTitle: 'Concessions',
                resource: 'concession',
                action: 'manage-concession',
                navActiveLink : 'concessions',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/concessions/:id',
            name: 'editer-concession',
            component: () => import('@/views/parametres/concessions/ConcessionEdit'),
            meta: {
                pageTitle: 'Concessions',
                resource: 'concession',
                action: 'manage-concession',
                navActiveLink : 'concessions',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
 
        {
            path: '/login',
            name: 'auth-login',
            component: () => import('@/views/Login'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },

        {
            path: '/not-authorized',
            name: 'misc-not-authorized',
            component: () => import('@/views/NotAuthorized.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'manage',
          
            },
        },
        {
            path: '/mot-de-passe-oublie',
            name: 'forgot-password',
            component: () => import('@/views/ForgotPassword.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
          
            },
        },
        {
            path: '/reset-password/:token',
            name: 'reset-password',
            component: () => import('@/views/ResetPassword.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,

            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})
router.beforeEach((to, _, next) => {
    console.log('--s');
    const isLoggedIn = isUserLoggedIn()
    if (!canNavigate(to)) {
        console.log('--');
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'auth-login' })

        // If logged in => not authorized
        return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
